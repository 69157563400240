window.envDev = $('body').hasClass('env-dev');

window.isTouchDevice = isTouchDevice();

/**
 * Performs a reload after a history-back event (i.e. after swiping right) on mobiles
 */
if (window.isTouchDevice) {
    window.addEventListener('pageshow', function (event) {
        const historyTraversal = event.persisted ||
            (typeof window.performance != 'undefined' &&
                window.performance.navigation.type === 2);
        if (historyTraversal) {
            // Handle page restore.
            window.location.reload();
        }
    });
}

/**
 * Checks it the deice is a touch device
 * @returns {boolean}
 */
function isTouchDevice () {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}
