/**
 * Improve bootstrap file chooser. Actually display the chosen file(s).
 */

window.initCustomFiles = function ($object = null) {

    if (null === $object) {
        $object = $('.custom-file');
    } else if (!$($object).hasClass('custom-file')) {
        $object = $($object).find('.custom-file');
    }

    $object.each(function () {

        var $label = $(this).find('.custom-file-label');
        var $fileInput = $(this).find('.custom-file-input');
        $fileInput.on('change', function (event) {
            const files = $(this)[0].files;
            let fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            $label.html(fileNames.join(', '));
        });
    });
};

window.initCustomFiles();

