/**
 * SCSS
 */
import '../scss/app.scss';

/**
 * JS
 */
import 'custom-event-polyfill';
import 'popper.js';
import 'bootstrap';

import './app/form-validation';
import './app/bootstrap';
import './app/select2';

import './app/main';

// Make jQuery globally available
window.$ = $;
