import 'select2';

/**
 * Adds select2 plugin to select element
 * @param {jQuery} $object
 */
window.initSelect = function ($object = null) {

    if (null === $object) {
        $object = $('.select2');
    } else if (!$($object).hasClass('select2') && !$($object).is('select')) {
        $object = $($object).find('.select2');
    }

    $($object).each(function () {

        // Prevent initializing, if it is prototype
        if ($(this).parents('.form-collection-prototype').length) {
            return;
        }

        // Add 100% width for not inline forms
        if (!$(this).closest('form').hasClass('form-inline')) {
            $(this).attr('style', 'width:100%');
        }

        // Determine options
        const options = {
            theme: 'bootstrap4',
            matcher: modelMatcher,
            minimumResultsForSearch: $(this).hasClass('no-search') ? Infinity : 8,
        };

        // Add placeholder data
        const placeholderData = $(this).data('placeholder');
        if (placeholderData) {
            options.placeholder = function () {
                return placeholderData;
            };
        }

        $(this).select2(options);
    });
};
window.initSelect();

function modelMatcher (params, data) {
    data.parentText = data.parentText || '';

    // Always return the object if there is nothing to compare
    if ($.trim(params.term) === '') {
        return data;
    }
    // Do a recursive check for options with children
    if (data.children && data.children.length > 0) {
        // Clone the data object if there are children
        // This is required as we modify the object to remove any non-matches
        var match = $.extend(true, {}, data);

        // Check each child of the option
        for (var c = data.children.length - 1; c >= 0; c--) {
            var child = data.children[c];
            child.parentText += data.parentText + ' ' + data.text;

            var matches = modelMatcher(params, child);

            // If there wasn't a match, remove the object in the array
            if (matches == null) {
                match.children.splice(c, 1);
            }
        }
        // If any children matched, return the new object
        if (match.children.length > 0) {
            return match;
        }
        // If there were no matching children, check just the plain object
        return modelMatcher(params, match);
    }

    // If the typed-in term matches the text of this term, or the text from any parent term, then it's a match.
    var original = (data.parentText + ' ' + data.text).toUpperCase();
    var term = params.term.toUpperCase();

    // Check if the text contains the term
    if (original.indexOf(term) > -1) {
        return data;
    }
    // If it doesn't contain the term, don't return anything
    return null;
}

