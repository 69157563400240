window.initFormValidation = function ($object = null) {

    if (null === $object) {
        $object = $('form');
    } else if (!$($object).is('form')) {
        $object = $($object).find('form');
    }

    $object.each(function () {

        const $form = $(this);
        const $submitButton = $(this).find('*[type=\'submit\']');
        let errorArray = [];

        // Init
        setFormValid();

        // Check on submission
        $(this).on('submit', function (event) {
            if (!checkForm()) {
                event.preventDefault();
                return false;
            }
        });

        $form.find('input[type=\'file\']').each(function () {
            const maxSize = $(this).data('max-size');
            // Check if data-max-size is set and browser supports files API
            if (maxSize && $(this)[0].files) {
                const errorIdentifier = 'fileSize';
                let maxSizeMessage = $(this).data('max-size-message');
                if (!maxSizeMessage) {
                    maxSizeMessage = 'Diese Datei ist zu groß';
                }
                $(this).on('change', function () {
                    // Calculate fileSize and set form in/valid
                    const files = $(this)[0].files;
                    if (files) {
                        let fileSize = 0.0;
                        files.forEach(function (file) {
                            fileSize += file.size;
                        });

                        if (fileSize > maxSize) {
                            addError($(this), errorIdentifier, maxSizeMessage);
                        } else {
                            removeError($(this), errorIdentifier);
                        }
                    }

                    checkForm();
                });
            }
        });

        $form.find('input[data-repeated]').each(function () {
            const errorIdentifier = 'repeated';

            const $element = $(this);

            const $originField = $($(this).data('repeated'));
            let repeatedMessage = $(this).data('repeated-message');
            if (!repeatedMessage) {
                repeatedMessage = 'Die Felder sind nicht identisch.';
            }

            $element.add($originField).on('change keyup', function () {

                if ($element.val() === '') {
                    return;
                }

                if ($originField.val() !== $element.val()) {
                    addError($element, errorIdentifier, repeatedMessage);
                } else {
                    removeError($element, errorIdentifier);
                }

                checkForm();

            });
        });

        $form.find('input[data-regex-multi]').each(function () {
            const errorIdentifier = 'regexMulti';

            const $element = $(this);

            const regexData = $(this).data('regex-multi');
            if (!Array.isArray(regexData)) {
                return;
            }

            $element.on('keyup change', function (event) {

                regexData.forEach(function (item, index) {
                    const regex = new RegExp(item.regex, item.option);

                    if (regex.test($element.val())) {
                        removeError($element, errorIdentifier + index);
                    } else {
                        addError($element, errorIdentifier + index, item.translated);
                    }
                });

                checkForm();

            });
        });

        function setFormValid () {
            $submitButton.prop('disabled', false);
        }

        function setFormInvalid () {
            $submitButton.prop('disabled', true);
        }

        function addError ($inputElement, errorIdentifier, errorString) {
            // Find form-group
            const $formGroup = $inputElement.closest('.form-group');
            $formGroup.addClass('has-error');

            // Create errorList
            let $errorList = $formGroup.children('ul');
            if (!$errorList.length) {
                $errorList = $('<ul class=\'list-unstyled\'>');
                $formGroup.append($errorList);
            }

            // Find previous error
            let $error = null;
            $errorList.children('li').each(function () {
                if ($(this).data('error-identifier') === errorIdentifier) {
                    $error = $(this);
                }
            });

            // Create error
            if (!$error) {
                $error = $('<li class=\'text-danger\'>');
                $error.data('error-identifier', errorIdentifier);
                $errorList.append($error);
            }
            $error.text(errorString);

            // Remember error in array
            const uniqueErrorId = $inputElement.prop('name') + errorIdentifier;
            if (!errorArray.includes(uniqueErrorId)) {
                errorArray.push(uniqueErrorId);
            }
        }

        function removeError ($inputElement, errorIdentifier) {
            // Find form-group
            const $formGroup = $inputElement.closest('.form-group');

            // Find errorList
            const $errorList = $formGroup.children('ul');

            // Find and remove error
            $errorList.children('li').each(function () {
                if ($(this).data('error-identifier') === errorIdentifier) {
                    $(this).remove();
                }
            });

            // Remove error class and errorList if list is empty
            if (!$errorList.children('li').length) {
                $errorList.remove();
                $formGroup.removeClass('has-error');
            }

            // Remove error from array
            const uniqueErrorId = $inputElement.prop('name') + errorIdentifier;
            if (errorArray.includes(uniqueErrorId)) {
                errorArray = errorArray.filter(function (item) {
                    return item !== uniqueErrorId;
                });
            }
        }

        function checkForm () {

            const valid = !errorArray.length;

            console.log(errorArray);

            if (valid) {
                setFormValid();
            } else {
                setFormInvalid();
            }
            return valid;
        }

    });
};

window.initFormValidation();
